import React, { useMemo } from "react";
import styled from "styled-components";

import Lozenge, { ThemeAppearance } from "@atlaskit/lozenge";

import { useEstimationField } from "../../../hooks/useEstimationField";
import { JiraIssue } from "../../../types";
import { getEstimationValue } from "../../../utils/estimation-values";
import { useGameIssues } from "../GameIssuesProvider";
import { useGameData } from "../GameProvider";

interface EstimatedIssuesHeaderProps {
  backlogIssues: JiraIssue[];
}

const EstimationInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 8px;
`;

export function EstimatedIssuesHeader({ backlogIssues }: Readonly<EstimatedIssuesHeaderProps>) {
  const { game } = useGameData();
  const estimationField = useEstimationField(game.configuration.estimationFieldId);
  const backlog = useMemo(() => game.configuration.backlog || [], [game.configuration.backlog]);
  const { estimatedIssues } = useGameIssues();
  const estimatedIssuesEntries = useMemo(() => Object.entries(estimatedIssues), [estimatedIssues]);

  const lozengeAppearance = useMemo<ThemeAppearance | undefined>(() => {
    if (!estimatedIssuesEntries?.length) return undefined;
    if (estimatedIssuesEntries.length < backlog.length) return "inprogress";
    else return "success";
  }, [estimatedIssuesEntries.length, backlog.length]);

  const estimationSum = useMemo(
    () =>
      backlogIssues.reduce<number | null>((sum, issue) => {
        const issueEstimationValue = issue.fields[game.configuration.estimationFieldId];
        if (sum === null || !issueEstimationValue) return sum;
        const numberValue = Number(issueEstimationValue);
        if (isNaN(numberValue)) return null;
        return sum + numberValue;
      }, 0),
    [backlogIssues, game.configuration.estimationFieldId],
  );

  const estimationSumValue = useMemo(
    () => getEstimationValue(estimationSum, estimationField),
    [estimationField, estimationSum],
  );

  return (
    <EstimationInfoWrapper>
      <Lozenge appearance={lozengeAppearance} isBold>
        ESTIMATED ISSUES: {estimatedIssuesEntries.length}/{backlog.length}
      </Lozenge>
      <Lozenge appearance="success" maxWidth={350}>
        {estimationField?.name &&
          estimationSumValue !== null &&
          `${estimationField.name.toUpperCase()} ESTIMATED: ${estimationSumValue}`}
      </Lozenge>
    </EstimationInfoWrapper>
  );
}
