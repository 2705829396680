import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import EditorLinkIcon from "@atlaskit/icon/glyph/editor/link";
import { token } from "@atlaskit/tokens";
import Tooltip from "@atlaskit/tooltip";

import { visuallyHidden, visuallyVisible } from "../../../styles/visuallyHidden";
import { useGameUrl } from "./useGameUrl";

const GameLinkShareCellContainer = styled.div`
  width: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GameLinkContainer = styled.div`
  flex: 1;
  min-width: 0;
  overflow: hidden;
  color: var(--ds-link, #0052cc);
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const GameShareContainer = styled.button`
  all: unset;
  ${visuallyHidden};

  cursor: pointer;

  tr:hover & {
    ${visuallyVisible};
  }
`;

const getShowTooltip = (buttonElement: HTMLElement | null) => {
  if (!buttonElement?.firstElementChild) return false;
  const buttonTextElement = buttonElement.firstElementChild;

  return buttonTextElement.scrollWidth > buttonTextElement.clientWidth;
};

interface GameNameCellProps {
  gameId: string;
  gameName: string;
}

export function GameLinkShareCell({ gameId, gameName }: GameNameCellProps) {
  // Must use state, because ref won't be updated
  const [linkElement, setLinkElement] = useState<HTMLElement | null>(null);

  const { copyGameUrl } = useGameUrl(gameId);

  const onLinkClick = () => history.pushState({ from: "dashboard" }, "");
  return (
    <GameLinkShareCellContainer>
      <GameLinkContainer data-testid={`game-${gameName}`}>
        {getShowTooltip(linkElement) ? (
          <Tooltip content={gameName}>
            <Link ref={setLinkElement} to={`/game/${gameId}`} onClick={onLinkClick}>
              {gameName}
            </Link>
          </Tooltip>
        ) : (
          <Link ref={setLinkElement} to={`/game/${gameId}`} onClick={onLinkClick}>
            {gameName}
          </Link>
        )}
      </GameLinkContainer>
      <GameShareContainer onClick={copyGameUrl}>
        <Tooltip content="Copy game URL">
          <EditorLinkIcon primaryColor={token("color.icon.subtle")} label="copy game url" />
        </Tooltip>
      </GameShareContainer>
    </GameLinkShareCellContainer>
  );
}
