import React from "react";

import { LinkButton } from "@atlaskit/button/new";
import { Box } from "@fuegokit/react";

import { TruncatedTooltipMemoized } from "../TruncatedTooltip";

interface UrlCellProps {
  url: string | undefined;
}

export function UrlCell({ url }: UrlCellProps) {
  if (!url) {
    return <>-</>;
  }
  return (
    <Box marginLeft="-12px" minWidth="0">
      <LinkButton href={url} appearance="link" target="_blank">
        <TruncatedTooltipMemoized tooltipText={url}>{url}</TruncatedTooltipMemoized>
      </LinkButton>
    </Box>
  );
}
