import pDebounce from "p-debounce";
import React, { FormEvent, useState } from "react";

import { ErrorMessage } from "@atlaskit/form";
import TextField from "@atlaskit/textfield";
import { Box } from "@fuegokit/react";

import { validateJQL } from "../../services/jira-api";
import { GameFormConfig } from "../../types";
import { SwitchElement } from "../shared/SwitchElement";

interface EstimationContextProps {
  gameConfig: GameFormConfig;
  onConfigChange: (toUpdate: Partial<GameFormConfig>) => void;
}

const debouncedValidateJql = pDebounce(validateJQL, 500);

export function EstimationContext({ onConfigChange, gameConfig }: EstimationContextProps) {
  const [isValid, setIsValid] = useState(true);
  const [query, setQuery] = useState(gameConfig.estimationContextCustomQuery);

  const setCustomQuery = async (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setQuery(target.value);
    const validatedQuery = await debouncedValidateJql(target.value);
    const isValid = !validatedQuery?.errors?.length;

    if (isValid) {
      onConfigChange({ estimationContextCustomQuery: target.value });
      setIsValid(true);
      return;
    }
    onConfigChange({ estimationContextCustomQuery: "" });
    setIsValid(false);
  };

  return (
    <Box display="flex" flex={1} flexDirection="column" sx={{ gap: "8px" }}>
      <SwitchElement
        label="Enable estimation context"
        value={gameConfig.enableEstimationContext}
        onToggle={() => onConfigChange({ enableEstimationContext: !gameConfig.enableEstimationContext })}
        id="enable-estimation-context"
        explanation="Users can find examples of issues in the Done status category with the selected estimation value in the Estimation context panel."
      ></SwitchElement>
      <SwitchElement
        label=" Limit issues pool for estimation context"
        value={gameConfig.limitEstimationContext}
        isDisabled={!gameConfig.enableEstimationContext}
        onToggle={() => onConfigChange({ limitEstimationContext: !gameConfig.limitEstimationContext })}
        explanation="Use to reduce default scope of the estimation context JQL. This will be an additional query command merged as AND condition."
        id="limit-estimation-context"
      />
      {gameConfig.enableEstimationContext && gameConfig.limitEstimationContext && (
        <>
          <TextField
            placeholder="i.e. labels IN (teamA, teamB)"
            value={query}
            isInvalid={!isValid}
            onChange={(event) => void setCustomQuery(event)}
          />
          {!isValid && <ErrorMessage>Invalid JQL</ErrorMessage>}
        </>
      )}
    </Box>
  );
}
