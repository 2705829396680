import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { EstimatedItemProps } from "@appfire/poker-core";
import Button from "@atlaskit/button";
import { IconButton } from "@atlaskit/button/new";
import OpenIcon from "@atlaskit/icon/glyph/open";
import RefreshIcon from "@atlaskit/icon/glyph/refresh";
import { SimpleTag as Tag } from "@atlaskit/tag";
import { Box } from "@fuegokit/react";

import { EditIssueClicked } from "../../analytics/ampli";
import { useRecordEvent } from "../../analytics/hooks/events/useRecordEvent";
import { useEstimationField } from "../../hooks/useEstimationField";
import { useLayoutState } from "../../hooks/useLayoutState";
import { useRefetchIssuesById } from "../../hooks/useRefetchIssuesById";
import { useJiraDataStore } from "../../providers/JiraDataStoreProvider";
import { JiraField, JiraIssue } from "../../types";
import { DEFAULT_COLUMNS } from "../../utils/backlog";
import { getEstimationValue } from "../../utils/estimation-values";
import { isFieldIdSupported, isFieldTypeSupported, isParagraphField } from "../../utils/fields";
import { Attachments } from "../Attachments";
import { Comments } from "../Comments";
import { AvatarCell } from "../dashboard/cells/AvatarCell";
import { Description, JiraHtmlRenderer } from "../Description";
import { EpicLabel } from "../shared/cells/EpicLabel";
import { IconCellMemoized } from "../shared/cells/IconCell";
import { IssueKeyCell } from "../shared/cells/IssueKeyCell";
import { StatusLabel } from "../shared/cells/StatusLabel";
import { TruncatedTooltipMemoized } from "../shared/TruncatedTooltip";
import { useGameData } from "./GameProvider";
import { CollapsiblePanel } from "./IssueDetails/CollapsiblePanel";
import { Details } from "./IssueDetails/Details";

const Divider = styled.div`
  border-radius: 1px 0 0 1px;
  background: var(--ds-border-selected, #0c66e4);
  height: 40px;
  width: 4px;
`;

const SummaryContainer = styled.div`
  overflow: auto;
`;

const useRefetchActiveItem = () => {
  const { refetchIssue } = useRefetchIssuesById();

  const [isLoading, setIsLoading] = useState(false);
  const refetchActiveItem = useCallback(
    async (activeItemId: string) => {
      setIsLoading(true);
      await refetchIssue(activeItemId);
      setIsLoading(false);
    },
    [refetchIssue],
  );

  return {
    refetchActiveItem,
    isLoading,
  };
};

export function ActiveItemComponent({ activeItem }: EstimatedItemProps<JiraIssue>) {
  const { game, gameId, isGameAdmin } = useGameData();
  const { fields = [] } = useJiraDataStore();
  const { refetchActiveItem, isLoading } = useRefetchActiveItem();
  const { layoutState, togglePanel } = useLayoutState();
  const recordEditIssueEvent = useRecordEvent(EditIssueClicked);
  const estimationField = useEstimationField(game.configuration.estimationFieldId);

  const estimatedValue = useMemo(() => {
    return getEstimationValue(activeItem?.fields[game.configuration.estimationFieldId], estimationField);
  }, [activeItem?.fields, game.configuration.estimationFieldId, estimationField]);

  const estimationFieldName = useMemo(() => {
    return fields.find((field) => field.id === game.configuration.estimationFieldId)?.name;
  }, [game.configuration.estimationFieldId, fields]);

  const layoutFields = useMemo(() => {
    const fieldMap = new Map(fields.map((field) => [field.id, field]));

    return game.configuration.layoutFields?.filter((fieldId) => {
      const field = fieldMap.get(fieldId);
      return (
        field && (isFieldIdSupported(field) || isFieldTypeSupported(field, DEFAULT_COLUMNS) || isParagraphField(field))
      );
    });
  }, [fields, game.configuration.layoutFields]);

  const openIssueDialog = useCallback(() => {
    if (!activeItem) {
      return;
    }

    AP.jira.openIssueDialog(activeItem.key, function () {
      void refetchActiveItem(activeItem.id);
    });
    recordEditIssueEvent({ game_id: gameId, is_game_admin: isGameAdmin, issue_id: activeItem.id });
  }, [activeItem, refetchActiveItem, recordEditIssueEvent]);

  useEffect(() => {
    activeItem?.id && void refetchActiveItem(activeItem.id);
  }, [activeItem?.id, refetchActiveItem]);

  const { detailsFields, paragraphFields } = useMemo(() => {
    const textFields = layoutFields?.filter((id) => !["comment", "attachment"].includes(id)) || [];
    return textFields.reduce<{ detailsFields: JiraField[]; paragraphFields: JiraField[] }>(
      (acc, fieldId) => {
        const field = fields.find((field) => field.id === fieldId);
        if (!field) {
          return acc;
        }
        if (isParagraphField(field)) {
          acc.paragraphFields.push(field);
        } else {
          acc.detailsFields.push(field);
        }
        return acc;
      },
      { detailsFields: [], paragraphFields: [] },
    );
  }, [layoutFields, fields]);

  const attachments = useMemo(() => activeItem?.fields.attachment || [], [activeItem?.fields.attachment]);

  if (!activeItem) {
    return null;
  }

  return (
    <Box>
      <Box display="flex" width="100%">
        <Divider />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          border="1px solid"
          borderRadius=" 0px 3px 3px 0px"
          borderColor="border.selected"
          backgroundColor="var(--ds-background-brand-subtlest)"
          alignItems="center"
          overflow="auto"
          borderLeft="none"
          width="100%"
          paddingLeft="8px"
          sx={{ boxSizing: "border-box" }}
        >
          <Box
            display="flex"
            flexDirection="row"
            width="auto"
            alignItems="center"
            overflow="auto"
            sx={{ gap: "8px", boxSizing: "border-box" }}
          >
            {estimatedValue && (
              <TruncatedTooltipMemoized
                tooltipText={`${estimationFieldName}:${estimatedValue}`}
                showTooltipOnEqualWidth
              >
                <Tag text={estimatedValue} />
              </TruncatedTooltipMemoized>
            )}
            <IconCellMemoized url={activeItem.fields.issuetype?.iconUrl} tooltip={activeItem.fields.issuetype?.name} />
            <IssueKeyCell issue={activeItem} />
            <SummaryContainer>
              <TruncatedTooltipMemoized tooltipText={activeItem.fields.summary}>
                {activeItem.fields.summary}
              </TruncatedTooltipMemoized>
            </SummaryContainer>
          </Box>
          <Box display="flex" sx={{ gap: "8px" }} alignItems="center" width="auto" marginLeft="8px" marginRight="8px">
            <TruncatedTooltipMemoized tooltipText={activeItem.fields?.status?.name} showTooltipOnEqualWidth>
              <StatusLabel issue={activeItem} maxWidth={100} />
            </TruncatedTooltipMemoized>
            {activeItem.fields.parent && (
              <TruncatedTooltipMemoized
                tooltipText={activeItem.fields.parent?.fields.summary ?? ""}
                showTooltipOnEqualWidth
              >
                <EpicLabel issue={activeItem} maxWidth={150} />
              </TruncatedTooltipMemoized>
            )}

            <IconCellMemoized url={activeItem.fields.priority?.iconUrl} tooltip={activeItem.fields.priority?.name} />
            <AvatarCell user={activeItem.fields.assignee} />
            <IconButton
              icon={(iconProps) => <RefreshIcon {...iconProps} size="small" />}
              label="Reload current issue"
              spacing="compact"
              isLoading={isLoading}
              isTooltipDisabled={false}
              onClick={() => void refetchActiveItem(activeItem.id)}
            />
            <Button
              iconBefore={<OpenIcon label="" size="small" />}
              appearance="default"
              spacing="compact"
              onClick={openIssueDialog}
            >
              Open issue
            </Button>
          </Box>
        </Box>
      </Box>
      <Box display="flex" padding="16px 0" gridGap="4px" height="100%">
        <Box flex="65" display="flex" flexDirection="column" gridGap="4px" minWidth="0">
          <Description
            issue={activeItem}
            isExpanded={layoutState["description"]}
            toggle={() => togglePanel("description")}
          />
          {paragraphFields.map((field) => {
            return (
              <CollapsiblePanel
                isExpanded={layoutState[field.id]}
                toggle={() => togglePanel(field.id)}
                key={field.id}
                title={field.name}
                secondaryText={activeItem.fields[field.id] as string}
              >
                {activeItem.renderedFields[field.id] ? (
                  <Box padding="16px">
                    <JiraHtmlRenderer text={activeItem.renderedFields[field.id] as string} attachments={attachments} />
                  </Box>
                ) : null}
              </CollapsiblePanel>
            );
          })}
          <Attachments
            isExpanded={layoutState["attachments"]}
            toggle={() => togglePanel("attachments")}
            attachments={attachments}
          />
        </Box>
        <Box flex="35" display="flex" flexDirection="column" minWidth="0" gridGap="4px">
          <Details
            isExpanded={layoutState["details"]}
            toggle={() => togglePanel("details")}
            detailsFields={detailsFields}
            activeItem={activeItem}
          />
          <Comments
            isExpanded={layoutState["comments"]}
            toggle={() => togglePanel("comments")}
            issue={activeItem}
            reFetchIssue={() => refetchActiveItem(activeItem.id)}
          />
        </Box>
      </Box>
    </Box>
  );
}
