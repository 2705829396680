import React from "react";

import { SimpleTag } from "@atlaskit/tag";

import { TruncatedTooltipMemoized } from "../TruncatedTooltip";

interface TagsCellProps {
  tags: string[];
}

export function TagsCell({ tags = [] }: TagsCellProps) {
  return (
    <TruncatedTooltipMemoized tooltipText={tags.join(", ")}>
      {tags.length ? tags.map((label) => <SimpleTag key={label} text={label} />) : "-"}
    </TruncatedTooltipMemoized>
  );
}
