import React, { Dispatch, SetStateAction, useEffect } from "react";

import { Box } from "@fuegokit/react";
import { ColumnDef } from "@tanstack/table-core";

import "./table.css";

import { useEpicsDetails } from "../../providers/EpicsDetailsProvider";
import { JiraField, JiraIssue } from "../../types";
import { ItemsTable } from "./ItemsTable";

interface IssuesTableProps {
  setRowSelection: Dispatch<SetStateAction<Record<string, boolean>>>;
  rowSelection: Record<string, boolean>;
  issues: JiraIssue[];
  isLoading: boolean;
  estimationFieldId?: string;
  columns: ColumnDef<JiraIssue, string>[];
  disabledItemIds: string[];
  fields: JiraField[];
  children?: React.ReactNode;
  draggable?: boolean;
  onOrderChange?: (items: JiraIssue[]) => void;
  onIssueClick?: (item: JiraIssue) => void;
  isWindowVirtualizer?: boolean;
}

function ItemDragPreview({ item }: { item: JiraIssue }) {
  return (
    <Box
      p={2}
      backgroundColor="var(--ds-surface-overlay, #FFFFFF)"
      display={"flex"}
      style={{ gap: "5px" }}
      alignItems={"center"}
      maxWidth="250px"
    >
      <img src={item.fields.issuetype?.iconUrl} width="16px" alt="icon" />
      <Box flexShrink={0}>{item.key}</Box>
      <span className="ellipsis">{item.fields.summary}</span>
    </Box>
  );
}

export function IssuesTable({
  issues,
  setRowSelection,
  rowSelection,
  columns,
  isLoading,
  disabledItemIds,
  fields,
  children,
  onIssueClick,
  isWindowVirtualizer,
  onOrderChange,
  draggable = false,
}: IssuesTableProps) {
  const { loadMissingEpics } = useEpicsDetails();

  useEffect(() => {
    fields && void loadMissingEpics(issues, fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issues, fields]);

  return (
    <ItemsTable<JiraIssue>
      disabledItemIds={disabledItemIds}
      isLoading={isLoading}
      columns={columns}
      items={issues}
      draggable={draggable}
      onOrderChange={onOrderChange}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      DraggedItemPreview={ItemDragPreview}
      onRowClick={onIssueClick}
      isWindowVirtualizer={isWindowVirtualizer}
    >
      {children}
    </ItemsTable>
  );
}
