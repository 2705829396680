import React, { useCallback, useState } from "react";

import Badge from "@atlaskit/badge";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";

import "./Attachments.css";

import { Attachment, MIME_TYPE } from "../types";
import { formatDateAndTime, getFileName, getType } from "../utils/attachments";
import { CollapsiblePanel } from "./game/IssueDetails/CollapsiblePanel";
import { FileIcon } from "./shared/FileIcon";

export function Cover({ attachment, controls = false }: { attachment: Attachment; controls?: boolean }) {
  switch (getType(attachment.mimeType)) {
    case MIME_TYPE.IMAGE:
      return <img src={`${attachment.content}`} alt="thumbnail" height="100%" />;
    case MIME_TYPE.VIDEO:
      return (
        <video height="100%" controls={controls}>
          <source src={attachment.content} />
        </video>
      );
    default:
      return <FileIcon />;
  }
}

export function Thumbnail({
  attachment,
  onAttachmentClick,
}: {
  attachment: Attachment;
  onAttachmentClick: (attachment: Attachment) => void;
}) {
  const isTypeOther = getType(attachment.mimeType) === MIME_TYPE.OTHER;
  const classes = isTypeOther ? "thumbnail" : "thumbnail pointer";
  const onClick = useCallback(() => {
    if (!isTypeOther) {
      onAttachmentClick(attachment);
    }
  }, [onAttachmentClick, attachment, isTypeOther]);

  return (
    <div className={classes} onClick={onClick}>
      <div className="cover">
        <Cover attachment={attachment} />
      </div>
      <div className="attachment-details">
        <div className="filename">{getFileName(attachment.filename)}</div>
        <div className="created ellipsis">{formatDateAndTime(attachment.created)}</div>
      </div>
    </div>
  );
}

const Counter = ({ count }: { count: number }) => {
  const styles = {
    backgroundColor: "var(--ds-background-information, #DEEBFF)",
    color: "var(--ds-text-information, #0052CC)",
  };
  return (
    <Badge style={styles} max={10} appearance={"primary"}>
      {count}
    </Badge>
  );
};

export function Attachments({
  attachments,
  isExpanded,
  toggle,
}: {
  attachments: Attachment[];
  isExpanded: boolean;
  toggle: VoidFunction;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [attachment, setAttachment] = useState<Attachment>();
  const secondaryText = attachments.map((attachment) => attachment.filename).join(", ");

  const onAttachmentClick = useCallback((attachment: Attachment) => {
    setAttachment(attachment);
    setIsOpen(true);
  }, []);

  return (
    <CollapsiblePanel
      isExpanded={isExpanded}
      toggle={toggle}
      title="Attachments"
      icon={<Counter count={attachments.length} />}
      secondaryText={secondaryText}
    >
      {attachments.length > 0 ? (
        <>
          <ModalTransition>
            {isOpen && attachment && (
              <Modal width="auto" onClose={() => setIsOpen(false)}>
                <Cover attachment={attachment} controls={true} />
              </Modal>
            )}
          </ModalTransition>
          <div className="attachments">
            {attachments.map((attachment) => {
              return <Thumbnail key={attachment.id} attachment={attachment} onAttachmentClick={onAttachmentClick} />;
            })}
          </div>
        </>
      ) : null}
    </CollapsiblePanel>
  );
}
