import React, { useCallback } from "react";
import removeMarkdown from "remove-markdown";
import styled from "styled-components";

import { IconButton } from "@atlaskit/button/new";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import ChevronRightIcon from "@atlaskit/icon/glyph/chevron-right";
import { Box, themeGet } from "@fuegokit/react";

const Section = styled.section`
  border-radius: 3px;
  border: 1px solid ${themeGet("colors.border.default")};
`;

const Header = styled.header<{ expandable: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;

  &:hover {
    background-color: var(--ds-background-neutral-subtle-hovered, #091e4214);
    cursor: ${(props) => (props.expandable ? "pointer" : "default")};
  }

  &:active {
    background-color: var(--ds-surface-overlay-pressed, #deebff);
  }

  button:hover {
    background: none;
  }
`;

const Title = styled.span`
  color: ${themeGet("colors.text.subtle")};
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
`;

const SecondaryText = styled.span`
  color: ${themeGet("colors.text.subtle")};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Content = styled.div<{ isExpanded: boolean }>`
  height: ${(props) => (props.isExpanded ? "100%" : 0)};
  overflow: hidden;
  border-top: ${(props) => (props.isExpanded ? "1px solid var(--ds-border)" : "none")};
`;

interface CollapsiblePanelProps {
  isExpanded: boolean;
  toggle: () => void;
  title: string;
  secondaryText?: string;
  alignItems?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

export function CollapsiblePanel({
  isExpanded,
  toggle,
  title,
  secondaryText,
  icon,
  children,
  alignItems = "baseline",
}: CollapsiblePanelProps) {
  const strippedText = children ? removeMarkdown(secondaryText || "") : "None";

  const onToggle = useCallback(() => {
    if (children) {
      toggle();
    }
  }, [toggle, children]);

  const isEmptyWithIcon = !children && icon;
  const hideSecondaryText = (children && isExpanded) || (!isExpanded && isEmptyWithIcon);

  return (
    <Section>
      <Header expandable={Boolean(children)} onClick={onToggle}>
        <Box display="flex" alignItems={alignItems} gridGap="8px" minWidth="0">
          <Title>{title}</Title>
          {icon || null}
          {!hideSecondaryText && <SecondaryText>{strippedText}</SecondaryText>}
        </Box>
        <Box width="24px" opacity={children ? 1 : 0}>
          <IconButton
            icon={isExpanded ? ChevronDownIcon : ChevronRightIcon}
            appearance="subtle"
            label={isExpanded ? "Collapse" : "Expand"}
          />
        </Box>
      </Header>
      {children && <Content isExpanded={isExpanded}>{children}</Content>}
    </Section>
  );
}
