import React, { FormEvent, useCallback, useState } from "react";

import SectionMessage from "@atlaskit/section-message";
import Select, { AsyncSelect } from "@atlaskit/select";
import TextField from "@atlaskit/textfield";
import { Box } from "@fuegokit/react";

import { getJiraBoards } from "../../services/jira-api";
import { GameFormConfig, StringOptionType } from "../../types";
import { SwitchableOption } from "../shared/SwitchableOption";
import { SprintProvider } from "./SprintProvider";
import { TransitionSelect } from "./TransitionSelect";

interface AutomationState {
  estimatedLabel: string;
  skippedLabel: string;
  estimatedTransition: string;
  estimatedSprintId: string;
}

interface AutomationSectionProps {
  gameConfig: GameFormConfig;
  onConfigChange: (update: Partial<GameFormConfig>) => void;
}

export function AutomationSection({ gameConfig, onConfigChange }: AutomationSectionProps) {
  const [automationState, setAutomationState] = useState<AutomationState>({
    estimatedLabel: gameConfig.estimatedLabel || "",
    skippedLabel: gameConfig.skippedLabel || "",
    estimatedTransition: gameConfig.estimatedTransition || "",
    estimatedSprintId: gameConfig.estimatedSprintId || "",
  });

  const onInputChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      onConfigChange({ [target.name as keyof GameFormConfig]: target.value });
      setAutomationState((prev) => ({ ...prev, [target.name]: target.value }));
    },
    [onConfigChange],
  );

  const onToggle = useCallback(
    (name: string, isEnabled: boolean) => {
      const value = automationState[name as keyof AutomationState];
      onConfigChange({
        [name as keyof GameFormConfig]: isEnabled ? value : null,
      });
    },
    [automationState, onConfigChange],
  );

  const resetSprint = useCallback(() => onConfigChange({ estimatedSprintId: null }), [onConfigChange]);

  return (
    <Box display="flex" flexDirection="column" gridGap={16} pt="8px">
      <SectionMessage appearance="information">
        Set up dependencies that will affect issues from game backlog after certain actions.
      </SectionMessage>
      <SwitchableOption
        name="estimatedLabel"
        label="Add labels after estimation"
        explanation="Select Labels (comma-separated) to be added to estimated issues."
        onToggle={onToggle}
        enabled={Boolean(gameConfig.estimatedLabel)}
      >
        <TextField
          name="estimatedLabel"
          value={automationState.estimatedLabel}
          placeholder="i.e. PlanningPoker-estimated"
          onChange={onInputChange}
        />
      </SwitchableOption>
      <SwitchableOption
        name="skippedLabel"
        label="Add labels to skipped issues"
        explanation="Select Labels (comma-separated) to be added to skipped issues."
        onToggle={onToggle}
        enabled={Boolean(gameConfig.skippedLabel)}
      >
        <TextField
          name="skippedLabel"
          value={automationState.skippedLabel}
          placeholder="i.e. PlanningPoker-skipped"
          onChange={onInputChange}
        />
      </SwitchableOption>
      <SwitchableOption
        name="estimatedTransition"
        label="Change issue status after estimation"
        explanation="Select a transition applied to estimated issues. For the successful status transaction, all workflow criteria must be met."
        onToggle={onToggle}
        enabled={Boolean(gameConfig.estimatedTransition)}
      >
        <TransitionSelect
          selectedTransition={automationState.estimatedTransition}
          setSelectedTransition={(update) => setAutomationState((prev) => ({ ...prev, estimatedTransition: update }))}
          onConfigChange={onConfigChange}
        />
      </SwitchableOption>
      <SprintProvider selectedSprintId={automationState.estimatedSprintId}>
        {({ selectedSprintOption, sprints, selectedBoard, setSelectedBoard }) => (
          <SwitchableOption
            name="estimatedSprintId"
            label="Move issue to sprint after estimation"
            explanation="Select new Sprint for estimated issues."
            onToggle={onToggle}
            enabled={Boolean(gameConfig.estimatedSprintId)}
          >
            <Box display="flex" flexDirection="column" gridGap="4px">
              <AsyncSelect
                inputId="select-board-after"
                placeholder="Select board..."
                isClearable={true}
                defaultOptions
                value={selectedBoard}
                loadOptions={loadBoards}
                onChange={(option: StringOptionType | null) => {
                  setSelectedBoard(option);
                  resetSprint();
                }}
              />
              {Boolean(selectedBoard?.value) && (
                <Select
                  inputId="select-sprint-after"
                  placeholder="Select sprint..."
                  isClearable={true}
                  value={selectedSprintOption}
                  options={sprints}
                  onChange={(option: StringOptionType | null) => {
                    setAutomationState((prev) => ({ ...prev, estimatedSprintId: option?.value ?? "" }));
                    onConfigChange({ estimatedSprintId: option ? option.value : null });
                  }}
                />
              )}
            </Box>
          </SwitchableOption>
        )}
      </SprintProvider>
    </Box>
  );
}

async function loadBoards(query: string) {
  return getJiraBoards(query).then((data) => data.values.map((s) => ({ label: s.name, value: s.id })));
}
