import React, { useCallback, useMemo, useState } from "react";

import Button, { IconButton } from "@atlaskit/button/new";
import Tooltip from "@atlaskit/tooltip";
import { AfTrophyIcon, AkEditorFileIcon } from "@fuegokit/fuegoicons-react";

import { useGameIssues } from "../GameIssuesProvider";

interface SaveEstimationButtonsProps {
  isLoading: boolean;
  isDisabled: boolean;
  isLastItem: boolean;
  onSave: (goToNextIssue?: boolean) => void;
}

function SaveIcon() {
  return <AkEditorFileIcon />;
}

function FinishGameIcon() {
  return <AfTrophyIcon />;
}

export function SaveEstimationButtons({
  isLoading,
  isDisabled,
  isLastItem,
  onSave,
}: Readonly<SaveEstimationButtonsProps>) {
  const saveButtonText = `Save & ${isLastItem ? "finish" : "next"}`;
  const { estimatedIssues, issues } = useGameIssues();
  const areAllIssuesExceptLastOneEstimated = useMemo(() => {
    const allIssueKeysExceptLastOne = Object.keys(issues).slice(0, -1);
    return allIssueKeysExceptLastOne.every((issueKey) => estimatedIssues[issueKey]);
  }, [estimatedIssues, issues]);
  const showReversedOrder = isLastItem && !areAllIssuesExceptLastOneEstimated;
  const [clickedButtonIndex, setClickedButtonIndex] = useState(0);

  const onEstimationSave = useCallback(
    (clickedButtonIndex: number, goToNextIssue: boolean = false) => {
      setClickedButtonIndex(clickedButtonIndex);
      onSave(goToNextIssue);
    },
    [onSave],
  );

  return !showReversedOrder ? (
    <>
      <Button
        appearance="primary"
        isLoading={isLoading && clickedButtonIndex === 0}
        isDisabled={isDisabled}
        onClick={() => onEstimationSave(0, true)}
        testId="save-estimation-button"
      >
        {saveButtonText}
      </Button>
      <Tooltip content="Save">
        <IconButton
          icon={SaveIcon}
          label="Save"
          isDisabled={isDisabled}
          onClick={() => onEstimationSave(1)}
          isLoading={isLoading && clickedButtonIndex === 1}
        />
      </Tooltip>
    </>
  ) : (
    <>
      <Button
        isDisabled={isDisabled}
        onClick={() => onEstimationSave(0)}
        isLoading={isLoading && clickedButtonIndex === 0}
        appearance="primary"
      >
        Save
      </Button>
      <Tooltip content="Save & finish">
        <IconButton
          icon={FinishGameIcon}
          label="Save & finish"
          isDisabled={isDisabled}
          isLoading={isLoading && clickedButtonIndex === 1}
          onClick={() => onEstimationSave(1, true)}
          testId="save-estimation-button"
        />
      </Tooltip>
    </>
  );
}
