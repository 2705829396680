import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { EmptyStateComponentProps } from "@appfire/poker-core/lib/types/EmptyStateIComponent";
import Button from "@atlaskit/button";
import { Box, Heading, Text } from "@fuegokit/react";

import { IssueSelected } from "../../../../analytics/ampli";
import { useRecordEvent } from "../../../../analytics/hooks/events/useRecordEvent";
import { JiraIssue } from "../../../../types";
import { useGameData } from "../../GameProvider";

interface EmptyStateModeratorProps {
  items: JiraIssue[];
  onSelectFirstAvailableActiveItem?: (newActiveItem: JiraIssue) => void;
}

function EmptyStateModerator({ items, onSelectFirstAvailableActiveItem }: EmptyStateModeratorProps) {
  const recordIssueSelectedEvent = useRecordEvent(IssueSelected);
  const { gameId } = useGameData();

  const selectFirstAvailableActiveItem = useCallback(() => {
    if (items.length === 0) return;

    const itemToSelect = items[0];
    onSelectFirstAvailableActiveItem?.(itemToSelect);
    recordIssueSelectedEvent({ game_id: gameId, issue_id: itemToSelect.id });
  }, [gameId, items, onSelectFirstAvailableActiveItem, recordIssueSelectedEvent]);

  return (
    <Box mb="40px" display="flex" flexDirection="column" alignItems="center" sx={{ gap: 3 }}>
      <img src="/images/game_empty_state_moderator.svg" alt="Poker cards" width={132} height={132} />
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ gap: 2 }}>
        <Heading variant="h600">Welcome to Planning Poker game</Heading>
        <Text textAlign="center">Click backlog issue to estimate or start game</Text>
      </Box>
      <Button appearance="primary" onClick={selectFirstAvailableActiveItem} testId="start-game-button">
        Start game
      </Button>
    </Box>
  );
}

function EmptyStateParticipant() {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" sx={{ gap: 3 }}>
      <img src="/images/game_empty_state_participant.svg" alt="Clock" width={100} height={100} />
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ gap: 2 }}>
        <Heading variant="h600">Welcome to Planning Poker game</Heading>
        <Text textAlign="center">Wait for the admin to start the game</Text>
      </Box>
    </Box>
  );
}

export function EmptyState({ items, isModerator, onActiveItemChange }: EmptyStateComponentProps<JiraIssue>) {
  return (
    <Box display="flex" justifyContent="center">
      {isModerator ? (
        <EmptyStateModerator items={items} onSelectFirstAvailableActiveItem={onActiveItemChange} />
      ) : (
        <EmptyStateParticipant />
      )}
    </Box>
  );
}

export function EmptyBacklogState({ isModerator }: EmptyStateComponentProps<JiraIssue>) {
  const navigate = useNavigate();
  const { gameId } = useGameData();
  return (
    <Box mb="40px" display="flex" flexDirection="column" alignItems="center" sx={{ gap: 3 }}>
      <img src="/icon/no-issues-glass.svg" alt="No issues glass" width={132} height={132} />
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ gap: 2 }}>
        <Heading variant="h600">There are no issues in backlog</Heading>
        <Text textAlign="center">
          {isModerator ? "Add issues to backlog to start a game" : "Wait for admin to add the pictures"}
        </Text>
      </Box>
      {isModerator && (
        <Button appearance="primary" onClick={() => navigate(`/game/${gameId}/backlog`)}>
          Edit backlog
        </Button>
      )}
    </Box>
  );
}
