import React from "react";

import { Box } from "@fuegokit/react";

import { AppfireStatement } from "./AppfireStatement";
import { April2022 } from "./April2022";
import { August2024 } from "./August2024";
import { February2022 } from "./February2022";
import { July2022 } from "./July2022";
import { July2024 } from "./July2024";

export function ChangelogModalContent() {
  return (
    <>
      <August2024 />
      <July2024 />
      <July2022 />
      <April2022 />
      <AppfireStatement />
      <February2022 />
      <Box>
        <h2>Missing a feature?</h2>
        <p>
          Let us know by emailing your suggestions to{" "}
          <a href="mailto:support@appfire.com" target="_blank" rel="noreferrer">
            support@appfire
          </a>
        </p>
      </Box>
    </>
  );
}
