import React from "react";

import { LinkButton } from "@atlaskit/button/new";
import { Box } from "@fuegokit/react";

import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { JiraIssue } from "../../../types";

interface ParentLinkCellProps {
  parent: JiraIssue | undefined;
}

export function ParentLinkCell({ parent }: ParentLinkCellProps) {
  const { hostBaseUrl } = useApplicationContext();

  return parent ? (
    <Box display="flex" alignItems="center">
      <img src={parent?.fields.issuetype?.iconUrl} alt="Issue type icon" />
      <LinkButton href={`${hostBaseUrl}/browse/${parent.key}`} appearance="link" target="_blank">
        {parent.fields.summary}
      </LinkButton>
    </Box>
  ) : (
    <>-</>
  );
}
