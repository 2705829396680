import React from "react";

import Lozenge from "@atlaskit/lozenge";
import { Skeleton } from "@fuegokit/react";

import { useEpicsDetails } from "../../../providers/EpicsDetailsProvider";
import { JiraIssue } from "../../../types";
import { hasEpic } from "../../../utils";
import { getEpicColorAndBackgroundColor } from "../../../utils/colors";

interface EpicLabelProps {
  issue: JiraIssue;
  maxWidth?: number;
}

export function EpicLabel({ issue, maxWidth = 200 }: Readonly<EpicLabelProps>) {
  const { epics } = useEpicsDetails();
  if (!hasEpic(issue)) return null;
  const epic = epics[issue.fields.parent?.id as string];
  if (!epic) return <Skeleton height={16} sx={{ marginBottom: 0 }} />;
  const [color, backgroundColor] = getEpicColorAndBackgroundColor(epic.color.key);
  return (
    <Lozenge
      style={{
        color,
        backgroundColor,
      }}
      maxWidth={maxWidth}
    >
      {issue.fields.parent?.fields.summary}
    </Lozenge>
  );
}
