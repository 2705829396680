import React from "react";
import styled from "styled-components";

import Avatar from "@atlaskit/avatar";
import { AkEditIcon } from "@fuegokit/fuegoicons-react";
import { Box } from "@fuegokit/react";

import "./cards.css";

import { CardType, UserData } from "../../../types/common";
import { VoteBadge } from "../../shared/CardDeck/VoteBadge";
import { Coffee } from "./Coffee";

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 1px 1px;
  padding: 0;
  padding-bottom: 0;
  box-sizing: border-box;
  transition: 400ms cubic-bezier(0.4, 0, 0.2, 1);

  transform-origin: bottom;
  height: 100%;
  width: 100%;
  line-height: 33px;
  position: relative;
  overflow: hidden;
  gap: 8px;
`;
const Shadow = styled.div`
  width: 75%;
  transition: width 0.2s ease-in-out;
  height: 4px;
  border-radius: 3px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.2) 52%, rgba(102, 102, 102, 0.03) 100%);
`;

interface RevealedCardProps {
  className?: string;
  card: CardType;
  isEditable?: boolean;
  isInSummary?: boolean;
  participantDetails?: UserData;
  selected?: boolean;
  onCardClicked?: (card: CardType) => void;
  onCardMouseEnter?: (estimate: string) => void;
  onCardMouseLeave?: () => void;
  onEditClick?: () => void;
}

export function RevealedCard({
  className,
  card,
  isEditable,
  isInSummary,
  participantDetails,
  selected,
  onCardClicked,
  onCardMouseEnter,
  onCardMouseLeave,
  onEditClick,
}: Readonly<RevealedCardProps>) {
  const cardLabel = card.label || card.value;
  const isCoffee = card.value === "coffee";

  return (
    <Box
      className={className}
      onClick={() => onCardClicked?.(card)}
      onMouseEnter={() => onCardMouseEnter?.(card.value)}
      onMouseLeave={onCardMouseLeave}
      data-testid="voting-card"
    >
      <div className="card-inner">
        <Box className="voting-card">
          <Box className="card-header">
            {isInSummary && participantDetails && <Avatar src={participantDetails.avatarUrl} size="xsmall" />}
            {isEditable && (
              <div className="edit-icon-container" onClick={onEditClick}>
                <AkEditIcon className="edit-icon " size={16} />
              </div>
            )}
          </Box>
          <Card>
            {isCoffee ? <Coffee /> : <VoteBadge className="vote-badge" label={cardLabel} />}
            <Box minHeight="16px" width="100%">
              {!selected && !isInSummary && <Box className="vote">Vote</Box>}
              {selected && <Box className="vote voted">Voted</Box>}
            </Box>
          </Card>
        </Box>
      </div>
      {!isInSummary && (
        <div className="shadow-underneath">
          <Shadow />
        </div>
      )}
    </Box>
  );
}
