import React, { useMemo } from "react";
import ReactDOM from "react-dom";

import { DropIndicator } from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";
import { Box } from "@fuegokit/react";
import { flexRender, Row } from "@tanstack/react-table";

import { useDraggableItem } from "../../dnd/hooks";
import { AbstractItem } from "../../types";

function TableRow<TItem extends AbstractItem>({
  start,
  row,
  index,
  scrollMargin,
  onClick,
  isSelected,
  draggable = false,
  DraggedItemPreview = DefaultPreview,
}: {
  start: number;
  index: number;
  scrollMargin: number;
  draggable?: boolean;
  row: Row<TItem>;
  isSelected: boolean;
  isDisabled: boolean;
  onClick?: () => void;
  DraggedItemPreview?: React.ComponentType<{ item: TItem }>;
}) {
  const { innerRef, state } = useDraggableItem<TItem>({ item: row.original, index, isDraggable: draggable });
  const cursor = useMemo(() => {
    if (draggable) return "grab";
    else if (onClick) return "pointer";
    return "default";
  }, [draggable, onClick]);
  const classes = `issues-table-row ${state.type === "dragging" ? "dragging" : ""} ${isSelected ? "selected" : ""}`;

  return (
    <>
      <Box
        ref={innerRef}
        className={classes}
        style={{
          transform: `translateY(${start - scrollMargin}px)`,
          cursor,
          zIndex: state.type === "is-over" && state.closestEdge ? 1 : undefined,
        }}
        onClick={onClick}
      >
        {row.getVisibleCells().map((cell) => {
          return (
            <Box
              className={`issues-table-cell ${cell.column.columnDef.meta?.cssId}`}
              key={cell.id}
              width={cell.column.getSize()}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Box>
          );
        })}
        {state.type === "is-over" && state.closestEdge && <DropIndicator edge={state.closestEdge} gap={`1px`} />}
      </Box>
      {state.type === "preview" && ReactDOM.createPortal(<DraggedItemPreview item={row.original} />, state.container)}
    </>
  );
}

export const TableRowMemoized = React.memo(TableRow, function (prevProps, nextProps) {
  return (
    prevProps.start === nextProps.start &&
    prevProps.scrollMargin === nextProps.scrollMargin &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.isDisabled === nextProps.isDisabled &&
    prevProps.row.original === nextProps.row.original &&
    prevProps.onClick === nextProps.onClick
  );
}) as typeof TableRow;

function DefaultPreview<Item extends AbstractItem>({ item }: { item: Item }) {
  return (
    <Box p={2} backgroundColor="var(--ds-surface-overlay, #FFFFFF)">
      <span>{item.key}</span>
    </Box>
  );
}
