import React from "react";

import { LinkButton } from "@atlaskit/button/new";
import { Box } from "@fuegokit/react";

import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { JiraProject } from "../../../types";

interface ProjectCellProps {
  project: JiraProject | undefined;
}

export function ProjectCell({ project }: ProjectCellProps) {
  const { hostBaseUrl } = useApplicationContext();

  return project ? (
    <Box display="flex" alignItems="center">
      <img src={project.avatarUrls["16x16"]} alt="Project icon" height="16px" />
      <LinkButton href={`${hostBaseUrl}/browse/${project.key}`} appearance="link" target="_blank">
        {project.name}
      </LinkButton>
    </Box>
  ) : (
    <>-</>
  );
}
