import React, { useEffect, useState } from "react";

import Select from "@atlaskit/select";

import { getTransitions } from "../../services/api";
import { GameFormConfig, StringOptionType } from "../../types";

interface TransitionSelectProps {
  selectedTransition: string;
  setSelectedTransition: (update: string) => void;
  onConfigChange: (update: Partial<GameFormConfig>) => void;
}
export function TransitionSelect({ selectedTransition, setSelectedTransition, onConfigChange }: TransitionSelectProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<StringOptionType | null>(null);
  const [transitionOptions, setTransitionOptions] = useState<StringOptionType[]>([]);

  useEffect(() => {
    void (async () => {
      try {
        setIsLoading(true);
        const transitions = await getTransitions();
        setTransitionOptions(transitions.map((transition) => ({ label: transition.name, value: transition.name })));
      } catch (err) {
        console.error("Failed to fetch transitions", err);
        setTransitionOptions([]);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const option = transitionOptions.find((option) => option.value.toLowerCase() === selectedTransition.toLowerCase());
    setSelectedOption(option ?? null);
  }, [selectedTransition, transitionOptions]);

  return (
    <Select
      inputId="select-transition-after"
      placeholder="Select transition..."
      value={selectedOption}
      onChange={(option: StringOptionType | null) => {
        setSelectedOption(option);
        setSelectedTransition(option?.value || "");
        onConfigChange({ estimatedTransition: option ? option.value : null });
      }}
      isLoading={isLoading}
      isDisabled={isLoading}
      options={transitionOptions}
    />
  );
}
