import React from "react";
import styled from "styled-components";

import Tooltip from "@atlaskit/tooltip";
import { Box } from "@fuegokit/react";

import { JiraIssue } from "../../../types";
import { TruncatedTooltipMemoized } from "../TruncatedTooltip";

interface ParentCellProps {
  parent: JiraIssue;
}

const ParentCellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export function ParentCell({ parent }: Readonly<ParentCellProps>) {
  return (
    <ParentCellWrapper>
      <Tooltip content={parent.fields.issuetype?.name}>
        <Box marginBottom="-3px">
          <img alt={parent.fields.issuetype?.name} src={parent.fields.issuetype?.iconUrl} />
        </Box>
      </Tooltip>
      <Box overflowX="hidden">
        <TruncatedTooltipMemoized tooltipText={parent.fields.summary}>{parent.fields.summary}</TruncatedTooltipMemoized>
      </Box>
    </ParentCellWrapper>
  );
}
