import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { JiraCloudIssuesSearchBar } from "@appfire/issues-search-bar";
import Button from "@atlaskit/button";
import { IconButton } from "@atlaskit/button/new";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import EditorAddIcon from "@atlaskit/icon/glyph/editor/add";
import Modal, { ModalFooter, ModalHeader, ModalTitle, ModalTransition } from "@atlaskit/modal-dialog";
import { Box } from "@fuegokit/react";

import { useApplicationContext } from "../../providers/ApplicationContextProvider";
import { useJiraDataStore } from "../../providers/JiraDataStoreProvider";
import { AddIssuesTable } from "./AddIssuesTable";
import { EmptyResult } from "./EmptyResult";
import { InfiniteLoader } from "./InfiniteLoader";
import { useQueryItemsByJQL } from "./useQueryItemsByJQL";

const SPRINT_FIELD_SCHEMA = "com.pyxis.greenhopper.jira:gh-sprint";
const DEFAULT_FILTERS = ["project", "issuetype", "status", "sprint"];
const EMPTY_CLONED_GAME_ACTION = "openAddIssue";

interface AddIssuesDialogProps {
  addMoreIssues: (issues: string[]) => void;
  disabledIssueKeys: string[];
  columnsIds: string[];
  estimationFieldId?: string;
  appearance?: "primary" | "default";
  jql?: string;
  onJqlChange: (jql: string) => void;
  shouldOpenOnLoad?: boolean;
}

export function AddIssuesDialog({
  addMoreIssues,
  disabledIssueKeys,
  columnsIds,
  estimationFieldId,
  appearance,
  jql,
  onJqlChange,
  shouldOpenOnLoad = false,
}: AddIssuesDialogProps) {
  const navigate = useNavigate();
  const isSomeSelected = disabledIssueKeys.length > 0;
  const type = appearance || (disabledIssueKeys?.length === 0 ? "primary" : "default");
  const queryFields = useMemo(
    () => (estimationFieldId ? [...columnsIds, estimationFieldId] : columnsIds),
    [columnsIds, estimationFieldId],
  );

  const { issues, loadNextPage, hasMore, loading } = useQueryItemsByJQL(queryFields, jql);
  const { hostBaseUrl } = useApplicationContext();

  const [rowSelection, setRowSelection] = React.useState({});
  const selectedIssues = useMemo(() => Object.keys(rowSelection), [rowSelection]);

  const [isOpen, setIsOpen] = useState(!isSomeSelected && shouldOpenOnLoad);
  const { id, action } = useParams() as { id: string; action: string };
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
    if (action === EMPTY_CLONED_GAME_ACTION) {
      navigate(`/game/${id}`, { replace: true });
    }
  }, [action, id, navigate]);
  const addIssues = useCallback(() => {
    addMoreIssues(selectedIssues);
    setRowSelection({});
    closeModal();
  }, [addMoreIssues, closeModal, selectedIssues]);

  const { fields = [] } = useJiraDataStore();
  const defaultFilters = useMemo(() => {
    const sprintField = fields?.find((f) => f.schema?.custom === SPRINT_FIELD_SCHEMA);
    if (sprintField) {
      return [...DEFAULT_FILTERS, sprintField.id];
    }
    return DEFAULT_FILTERS;
  }, [fields]);

  useEffect(() => {
    action === EMPTY_CLONED_GAME_ACTION && openModal();
  }, [action, openModal]);

  return (
    <>
      <Button
        appearance={type}
        onClick={openModal}
        iconBefore={<EditorAddIcon label="Add Issues" />}
        testId="open-add-issues-dialog-button"
      >
        Add Issues
      </Button>
      <ModalTransition>
        {isOpen && (
          <Modal width={issues.length > 0 ? "auto" : "min(850px, 90%)"} height="100%">
            <ModalHeader>
              <ModalTitle>Add issues to game backlog</ModalTitle>
              <IconButton appearance="subtle" onClick={closeModal} label="Close Modal" icon={CrossIcon} />
            </ModalHeader>
            <Box px="24px">
              <JiraCloudIssuesSearchBar
                showIssueSearch
                jqlQuery={jql}
                onJqlQueryChange={onJqlChange}
                jiraBaseUrl={hostBaseUrl}
                initialFieldIds={defaultFilters}
              />
            </Box>
            <Box display="flex" flexDirection="column" minHeight={0} px="24px" flex={1}>
              {jql && (
                <AddIssuesTable
                  disabledIssueKeys={disabledIssueKeys}
                  isLoading={loading}
                  issues={issues}
                  fields={fields}
                  estimationFieldId={estimationFieldId}
                  columnsIds={columnsIds}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                >
                  <InfiniteLoader loading={loading} loadNextPage={loadNextPage} hasMore={hasMore} />
                </AddIssuesTable>
              )}
              {<EmptyResult count={issues.length} loading={loading} jql={jql} />}
            </Box>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Close
              </Button>
              <Button
                isDisabled={!selectedIssues.length}
                appearance="primary"
                onClick={addIssues}
                testId="add-issues-button"
              >
                Add {selectedIssues.length} issue(s)
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
}
