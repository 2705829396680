import React from "react";
import styled from "styled-components";

import { CurrentReferenceContext } from "@appfire/poker-core/lib/hooks/useReferenceItems";
import Avatar from "@atlaskit/avatar";
import { SimpleTag } from "@atlaskit/tag";
import Tooltip from "@atlaskit/tooltip";
import { Spinner, themeGet } from "@fuegokit/react";

import { JiraIssue } from "../../../../../../types";
import { IconCellMemoized } from "../../../../../shared/cells/IconCell";
import { IssueKeyCell } from "../../../../../shared/cells/IssueKeyCell";
import { TruncatedTooltipMemoized } from "../../../../../shared/TruncatedTooltip";

const ContentStateManagerLoader = styled.div`
  padding: 36px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentStateManagerText = styled.p`
  padding: 36px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const ContentStateManagerCardContainer = styled.div`
  padding: 8px;
  /* Cards look ugly when they are getting squished */
  height: 120px;
  display: flex;
  gap: 8px;
  overflow: auto hidden;

  .content-state-manager-card {
    border: 1px solid ${themeGet("colors.border.default")};
    border-radius: 3px;
    height: 100%;
    min-width: 300px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
  }

  .content-state-manager-card-content {
    flex: 1;
    padding: 8px;
    background: ${themeGet("colors.elevation.surface.sunken")};
  }

  .content-state-manager-card-footer {
    padding: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content-state-manager-card-footer-group {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`;

interface ContentStateManagerProps {
  isLoading?: boolean;
  currentReferences: JiraIssue[];
  currentReferencesContext: CurrentReferenceContext | null;
}

export function ContentStateManager({
  isLoading,
  currentReferences,
  currentReferencesContext,
}: ContentStateManagerProps) {
  if (isLoading) {
    return (
      <ContentStateManagerLoader>
        <Spinner />
      </ContentStateManagerLoader>
    );
  }

  if (!currentReferencesContext) {
    return (
      <ContentStateManagerText>
        Hover over a card to see issues estimated similarly in the Done status category
      </ContentStateManagerText>
    );
  }

  if (currentReferences.length === 0) {
    return <ContentStateManagerText>Reference issues not found</ContentStateManagerText>;
  }

  return (
    <ContentStateManagerCardContainer>
      {currentReferences.map((issue) => (
        <div className="content-state-manager-card" key={issue.id}>
          <div className="content-state-manager-card-content">
            <TruncatedTooltipMemoized tooltipText={issue.fields.summary}>
              {issue.fields.summary}
            </TruncatedTooltipMemoized>
          </div>
          <div className="content-state-manager-card-footer">
            <div className="content-state-manager-card-footer-group content-state-manager-card-footer-summary">
              <IconCellMemoized url={issue.fields.issuetype?.iconUrl} tooltip={issue.fields.issuetype?.name} />
              <IssueKeyCell issue={issue} />
            </div>
            <div className="content-state-manager-card-footer-group">
              <SimpleTag text={currentReferencesContext.estimate} />
              <IconCellMemoized url={issue.fields.priority?.iconUrl} tooltip={issue.fields.priority?.name} />
              <Tooltip content={issue.fields.assignee?.displayName || "Unassigned"}>
                <Avatar src={issue.fields.assignee?.avatarUrls["16x16"]} size="small" />
              </Tooltip>
            </div>
          </div>
        </div>
      ))}
    </ContentStateManagerCardContainer>
  );
}
