import React, { ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Tooltip from "@atlaskit/tooltip";

import { isEllipsisActive } from "../../utils";

interface TruncatedTooltipProps {
  children: React.ReactNode;
  tooltipText?: string;
  showTooltipOnEqualWidth?: boolean;
}

const TruncatedText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const TruncatedTooltipMemoized = React.memo(function TruncatedTooltip({
  children,
  tooltipText,
  showTooltipOnEqualWidth = false,
}: TruncatedTooltipProps) {
  const elementRef = useRef<HTMLDivElement>(null);
  const [tooltip, setTooltip] = useState<ReactNode>();
  useEffect(() => {
    const tooltipContent = isEllipsisActive(elementRef.current, showTooltipOnEqualWidth) ? tooltipText : "";
    setTooltip(tooltipContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipText, elementRef.current?.offsetWidth, showTooltipOnEqualWidth]);

  return (
    <Tooltip content={tooltip}>
      <TruncatedText ref={elementRef}>
        <span>{children}</span>
      </TruncatedText>
    </Tooltip>
  );
});
