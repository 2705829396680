import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from "react";

import { amplitudePlugin } from "@appfire/analytics-client";

import { useApplicationContext } from "../providers/ApplicationContextProvider";
import { getErrorMessage } from "../utils";
import { ampli, Event } from "./ampli";
import { usePendo } from "./hooks/usePendo";
import { useUpdateGroupProperties } from "./hooks/useUpdateGroupProperties";
import { analyticsFallback } from "./utils";

interface AnalyticsContextData {
  recordEvent: (event: Event) => void;
}

const AnalyticsContext = createContext<AnalyticsContextData | undefined>(undefined);

export function AnalyticsContextProvider({ children }: Readonly<{ children: ReactNode }>) {
  const { clientKey, license, userAccountId, version } = useApplicationContext();
  const updateGroupProperties = useUpdateGroupProperties();
  const { initPendo, trackPendoEvent } = usePendo();

  useEffect(() => {
    void (async () => {
      try {
        await amplitudePlugin.init(
          ampli,
          {
            environment: process.env.ENVIRONMENT === "production" ? "production" : "development",
            client: {
              configuration: { defaultTracking: { attribution: false } },
            },
          },
          {
            hostId: clientKey,
            licenseId: analyticsFallback(license?.entitlementNumber),
            userId: userAccountId,
            appVersion: version,
            fingerprintApiKey: process.env.FINGERPRINT_API_KEY,
          },
        );

        await updateGroupProperties();
        await initPendo();
      } catch (error) {
        console.error("Failed to initialize analytics", getErrorMessage(error));
      }
    })();
  }, [clientKey, initPendo, license, updateGroupProperties, userAccountId, version]);

  const recordEvent = useCallback(
    (event: Event) => {
      ampli.track(event);
      void trackPendoEvent(event);
    },
    [trackPendoEvent],
  );

  const contextValue = useMemo(() => ({ recordEvent }), [recordEvent]);

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
}

export function useAnalyticsContext() {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("Analytics context not initialized, probably context provider is missing");
  }
  return context;
}
