import React from "react";
import styled from "styled-components";

import Avatar from "@atlaskit/avatar";
import Tooltip from "@atlaskit/tooltip";
import { Box } from "@fuegokit/react";

import { JiraUser } from "../../../types";

const UserName = styled.div`
  color: var(--ds-text, #172b4d);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

interface AvatarCellProps {
  user: JiraUser | null;
  showName?: boolean;
}

export function AvatarCell({ user, showName = false }: Readonly<AvatarCellProps>) {
  if (!user) return null;
  return (
    <Box display="flex" alignItems="center" gridGap="8px">
      <Tooltip content={user.displayName}>
        <Avatar src={user.avatarUrls["16x16"]} size="small" />
      </Tooltip>
      {showName && <UserName>{user.displayName}</UserName>}
    </Box>
  );
}
