import { camelCase } from "lodash";
import { useCallback } from "react";

import { useApplicationContext } from "../../providers/ApplicationContextProvider";
import { getErrorMessage } from "../../utils";
import { Event } from "../ampli";
import { EVENT_CATEGORIES_MAPPING } from "../utils/event-categories";

export function usePendo() {
  const { userAccountId, license, url, clientKey } = useApplicationContext();
  const initPendo = useCallback(async () => {
    if (process.env.ENVIRONMENT !== "production" || !license?.entitlementNumber) return;
    try {
      await pendo.initialize({
        visitor: {
          id: userAccountId,
        },
        account: {
          id: license.entitlementNumber ?? "unavailable",
          url: url.displayUrl,
          license_type: license.evaluation ? "EVALUATION" : license.type,
          client_key: clientKey,
        },
      });
    } catch (error) {
      console.warn("Failed to initialize pendo", getErrorMessage(error));
    }
  }, [clientKey, license, url.displayUrl, userAccountId]);

  const trackPendoEvent = useCallback(async (event: Event) => {
    try {
      const eventCategory = EVENT_CATEGORIES_MAPPING[event.event_type];
      if (!eventCategory) {
        console.warn(`Event category mapping missing for event ${event.event_type}`);
      }
      if (pendo?.isReady?.()) {
        const eventName = `${eventCategory}:${camelCase(event.event_type)}`;
        await pendo.track(eventName, event.event_properties);
      }
    } catch (error) {
      console.warn(`Failed to track pendo event - ${event.event_type}: ${getErrorMessage(error)}`);
    }
  }, []);

  return { initPendo, trackPendoEvent };
}
