import React from "react";
import styled from "styled-components";

import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { JiraIssue } from "../../../types";

const IssueLink = styled.a`
  white-space: nowrap;
`;

interface IssueKeyCellProps {
  issue: JiraIssue;
}

const generateIssueLink = (hostBaseUrl: string, issueKey: string) => {
  return `${hostBaseUrl}/browse/${issueKey}`;
};

export function IssueKeyCell({ issue }: Readonly<IssueKeyCellProps>) {
  const { hostBaseUrl } = useApplicationContext();

  return (
    <IssueLink href={generateIssueLink(hostBaseUrl, issue.key)} rel="noopener noreferrer" target="_blank">
      {issue.key}
    </IssueLink>
  );
}
