import React, { useCallback, useMemo, useState } from "react";

import SectionMessage from "@atlaskit/section-message";
import Select from "@atlaskit/select";
import { Box } from "@fuegokit/react";

import { useJiraDataStore } from "../../providers/JiraDataStoreProvider";
import { GameFormConfig } from "../../types";
import { DEFAULT_COLUMNS } from "../../utils/backlog";
import { isFieldIdSupported, isFieldTypeSupported, isParagraphField } from "../../utils/fields";
import { SwitchableOption } from "../shared/SwitchableOption";

interface LayoutCustomizationProps {
  gameConfig: GameFormConfig;
  onConfigChange: (toUpdate: Partial<GameFormConfig>) => void;
}

export function LayoutCustomization({ gameConfig, onConfigChange }: LayoutCustomizationProps) {
  const { fields, isLoading } = useJiraDataStore();
  const options = useMemo(
    () =>
      fields
        ?.filter((field) => gameConfig.estimationFieldId !== field.id)
        ?.filter(
          (field) =>
            isFieldIdSupported(field) || isFieldTypeSupported(field, DEFAULT_COLUMNS) || isParagraphField(field),
        )
        .map((field) => ({ label: field.name, value: field.id })) || [],
    [fields, gameConfig.estimationFieldId],
  );

  const defaultSelection = useMemo(() => {
    const selected: { label: string; value: string }[] = [];
    gameConfig.layoutFields?.forEach((value) => {
      const option = options.find((o) => o.value === value);
      if (option) {
        selected.push(option);
      }
    });
    return selected;
  }, [gameConfig.layoutFields, options]);

  const [selection, setSelection] = useState<Readonly<{ label: string; value: string }[]>>(defaultSelection);

  const onToggle = useCallback(
    (name: string, isEnabled: boolean) => {
      onConfigChange({ layoutFields: isEnabled ? selection.map((option) => option.value) : [] });
    },
    [onConfigChange, selection],
  );

  return (
    <SwitchableOption
      name="layoutCustomization"
      label="Add custom fields to game layout"
      explanation=""
      enabled={Boolean(gameConfig.layoutFields?.length)}
      onToggle={onToggle}
    >
      <Box display="flex" flexDirection="column" gridGap="4px">
        <SectionMessage appearance="information">
          Custom field will be automatically distributed by the order of selection
        </SectionMessage>
        <Select
          value={selection}
          options={options}
          onChange={(options) => {
            setSelection(options);
            onConfigChange({ layoutFields: options.map((option) => option.value) });
          }}
          isLoading={isLoading}
          isMulti
          isSearchable={true}
          isClearable={true}
          placeholder="Select fields"
        />
      </Box>
    </SwitchableOption>
  );
}
