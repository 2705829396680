import React, { Dispatch, FormEvent, SetStateAction, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import Badge from "@atlaskit/badge";
import Button from "@atlaskit/button";
import { Label } from "@atlaskit/form";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import SearchIcon from "@atlaskit/icon/glyph/search";
import { CheckboxOption, PopupSelect } from "@atlaskit/select";
import TextField from "@atlaskit/textfield";
import { Box } from "@fuegokit/react";

import { GameFilters, GameState, JiraUser, Users } from "../../types";
import { AvatarItemCell } from "./cells/AvatarItemCell";

interface FiltersProps {
  filters: GameFilters;
  setFilters: Dispatch<SetStateAction<GameFilters>>;
  creators: Users;
}

interface SelectOption {
  label: string;
  value: string | number;
}

interface CreatorOption extends SelectOption {
  creator: JiraUser;
}

interface PopUpSelectTargetProps {
  itemsCount: number;
  isOpen: boolean;
  filterName: string;
  value: string;
  triggerProps: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any;
    onKeyDown: React.KeyboardEventHandler<HTMLElement>;
    "aria-haspopup": "true";
    "aria-expanded": boolean;
    "aria-controls"?: string | undefined;
  };
}

const DropDownWrapper = styled.div`
  flex: 1;
  text-transform: capitalize;
`;

const TextSearchField = styled(TextField)`
  height: 32px;
  padding: 0 8px;
  width: 240px;
  margin-top: 22px;
`;

const UserSelectOption = ({ creator }: Omit<CreatorOption, "value">) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginLeft: "2px" }}>
        <AvatarItemCell creator={creator} size="small" />
      </div>
    </div>
  );
};
const PopUpSelectTarget = ({ itemsCount, isOpen, filterName, value, triggerProps }: PopUpSelectTargetProps) => {
  return (
    <Button
      {...triggerProps}
      appearance="default"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      isSelected={isOpen || !!itemsCount}
      className="game-filter"
      style={{
        width: "240px",
        alignItems: "center",
        textAlign: "left",
        fontWeight: 400,
        outline: "none",
      }}
      iconAfter={<ChevronDownIcon primaryColor={"var(--ds-text-subtle, #42526E)"} label="" />}
      testId={`${filterName.toLowerCase()}-filter`}
    >
      <Box display="flex">
        <div style={{ maxWidth: "150px", display: "flex" }}>
          {itemsCount > 0 && <span className="select-filter-label">{filterName}: </span>}
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: itemsCount ? "inherit" : "var(--ds-text-subtlest, #6B778C)",
            }}
          >
            {itemsCount ? value : "Any"}
          </span>
        </div>
        <div style={{ marginLeft: "4px" }}>
          {itemsCount > 1 && <Badge appearance="primary">+{itemsCount - 1}</Badge>}
        </div>
      </Box>
    </Button>
  );
};
const stateFilterOptions = Object.values(GameState).map((gameState) => ({ value: gameState, label: gameState }));

export function Filters({ filters, setFilters, creators }: FiltersProps) {
  const [selectedCreators, setSelectedCreators] = useState<CreatorOption[]>([]);
  const renderUserOption = useCallback(
    (option: CreatorOption) => <UserSelectOption label={option.label} creator={option.creator} />,
    [],
  );
  const creatorOptions = useMemo(
    () =>
      Object.values(creators).map((creator) => ({
        label: creator.displayName,
        value: creator.accountId,
        creator,
      })),
    [creators],
  );
  const updateTextFilter = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setFilters((prev) => ({ ...prev, search: target.value }));
  };
  const updateGameStateFilter = (data: Readonly<SelectOption[]>) => {
    setFilters((prev) => ({ ...prev, gameState: data.map((state) => state.value as string) }));
  };
  const updateCreatorFilter = (data: Readonly<CreatorOption[]>) => {
    setSelectedCreators(
      data.map(({ value, label, creator }: CreatorOption) => ({
        label,
        value,
        creator,
      })) as CreatorOption[],
    );
    setFilters((prev) => ({ ...prev, creator: data.map(({ value }: CreatorOption) => value as string) }));
  };
  const selectedStates = useMemo(
    () => stateFilterOptions.filter((state) => filters.gameState.includes(state.value)),
    [filters.gameState],
  );

  return (
    <Box display="flex" alignItems="end" sx={{ gap: "8px" }}>
      <TextSearchField
        placeholder="Search"
        onChange={updateTextFilter}
        elemBeforeInput={
          <Box as="span" marginLeft={1}>
            <SearchIcon primaryColor={"var(--ds-text-subtle, #42526E)"} size="small" label="search-icon" />
          </Box>
        }
      />
      <DropDownWrapper>
        <Label htmlFor="game-state-filter">State</Label>
        <PopupSelect
          inputId="game-state-filter"
          className="game-filter"
          components={{ Option: CheckboxOption }}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isMulti
          options={stateFilterOptions}
          placeholder="Game state"
          target={({ isOpen, ...triggerProps }) => (
            <PopUpSelectTarget
              isOpen={isOpen}
              triggerProps={triggerProps}
              itemsCount={selectedStates.length}
              filterName="States"
              value={selectedStates[0]?.label}
            />
          )}
          onChange={updateGameStateFilter}
          value={selectedStates}
          controlShouldRenderValue={false}
          isClearable={false}
          isSearchable={false}
          searchThreshold={-1}
          minMenuWidth={240}
        />
      </DropDownWrapper>
      <DropDownWrapper>
        <Label htmlFor="game-creator-filter">Creator</Label>
        <PopupSelect
          inputId="game-creator-filter"
          components={{ Option: CheckboxOption }}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isMulti
          options={creatorOptions}
          placeholder="Select creator"
          target={({ isOpen, ...triggerProps }) => (
            <PopUpSelectTarget
              isOpen={isOpen}
              triggerProps={triggerProps}
              itemsCount={selectedCreators.length}
              filterName="Creator"
              value={selectedCreators[0]?.label}
            />
          )}
          onChange={updateCreatorFilter}
          value={selectedCreators}
          controlShouldRenderValue={false}
          isClearable={false}
          isSearchable={creatorOptions.length > 5}
          searchThreshold={-1}
          formatOptionLabel={renderUserOption}
          minMenuWidth={240}
        />
      </DropDownWrapper>
    </Box>
  );
}
