import React, { useEffect, useMemo } from "react";

import { Field } from "@atlaskit/form";
import Select from "@atlaskit/select";

import { useJiraDataStore } from "../../providers/JiraDataStoreProvider";
import { Game, JiraField } from "../../types";
import { getDefaultEstimationField, isEstimationFieldSupported } from "../../utils";
import { FormLabel } from "./FormLabel";

const PRIORITY_FIELDS_NAMES = ["story points", "story point estimate", "original estimate"];

interface EstimationFieldOption {
  label: string;
  value: string;
  raw?: JiraField;
}

const getEstimationFieldOptions = (fields: JiraField[]): EstimationFieldOption[] => {
  const suggested: EstimationFieldOption[] = [];
  const rest: EstimationFieldOption[] = [];

  fields.forEach((field) => {
    if (!isEstimationFieldSupported(field)) return;

    const option = { label: `${field.name} [${field.id}]`, value: field.id, raw: field };
    if (PRIORITY_FIELDS_NAMES.includes(field.name.toLowerCase())) {
      suggested.push(option);
    } else {
      rest.push(option);
    }
  });
  suggested.push({ label: "Comments", value: "comments" });

  return suggested.concat(rest);
};

export const getEstimationFieldDefault = (fields: JiraField[], lastGame?: Game): EstimationFieldOption => {
  const foundField = getDefaultEstimationField(fields, lastGame);

  return foundField
    ? { label: `${foundField.name} [${foundField.id}]`, value: foundField.id, raw: foundField }
    : { label: "Comments", value: "comments" };
};

interface EstimationFieldSelectProps {
  selectedEstimationFieldId?: string;
  lastGame?: Game;
  isDisabled?: boolean;
  fixIncorrectField?: boolean;
  onEstimationFieldIdSelect: (estimationFieldId: string) => void;
}

export function EstimationFieldSelect({
  selectedEstimationFieldId,
  lastGame,
  isDisabled,
  fixIncorrectField,
  onEstimationFieldIdSelect,
}: Readonly<EstimationFieldSelectProps>) {
  const { fields, isLoading } = useJiraDataStore();

  const estimationFieldOptions = useMemo(() => getEstimationFieldOptions(fields ?? []), [fields]);
  const defaultEstimationField = useMemo(() => getEstimationFieldDefault(fields ?? [], lastGame), [fields, lastGame]);
  const selectedEstimationFieldOption = useMemo(
    () =>
      estimationFieldOptions.find((fieldOption) => fieldOption.value === selectedEstimationFieldId) ||
      defaultEstimationField,
    [defaultEstimationField, estimationFieldOptions, selectedEstimationFieldId],
  );
  // Replace incorrect estimation field when necessary
  useEffect(() => {
    if (!fixIncorrectField) return;
    if (selectedEstimationFieldOption.value === selectedEstimationFieldId) return;

    onEstimationFieldIdSelect(selectedEstimationFieldOption.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixIncorrectField, onEstimationFieldIdSelect]);

  return (
    <Field
      name="estimation-field"
      label={<FormLabel text="Estimation field" tooltip="Select an estimation field to save estimates to." />}
    >
      {() => (
        <Select
          value={selectedEstimationFieldOption}
          options={estimationFieldOptions}
          isLoading={isLoading}
          isDisabled={isDisabled}
          placeholder="Choose an estimation field"
          onChange={(option) => {
            if (option?.value === undefined) return;

            onEstimationFieldIdSelect(option.value);
          }}
          id="estimation-field-select"
        />
      )}
    </Field>
  );
}
