import React, { useCallback, useMemo, useState } from "react";

import Button from "@atlaskit/button";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import { CheckboxOption, OptionType, PopupSelect } from "@atlaskit/select";

import { useJiraDataStore } from "../../providers/JiraDataStoreProvider";
import { isFieldIdSupported, isFieldTypeSupported } from "../../utils/fields";

interface ColumnsSelectorProps {
  excludedColumns: string[];
  onSelectedColumnsChange: (values: string[]) => void;
  selectedColumnIds: string[];
}

type MultiValue<Option> = readonly Option[];

export function ColumnsSelector({
  excludedColumns,
  selectedColumnIds,
  onSelectedColumnsChange,
}: Readonly<ColumnsSelectorProps>) {
  const { fields } = useJiraDataStore();
  const [columns, setColumns] = useState<string[]>(selectedColumnIds);

  const columnsOptions = useMemo(
    () =>
      fields
        ?.filter((field) => isFieldIdSupported(field) || isFieldTypeSupported(field, excludedColumns))
        .map((field) => ({ value: field.id, label: field.name })),
    [excludedColumns, fields],
  );

  const selectedColumns = useMemo(
    () => columnsOptions?.filter((column) => columns.includes(column.value)),
    [columnsOptions, columns],
  );

  const onChange = useCallback(
    (options: MultiValue<OptionType>) => {
      const ids = options.map((o) => o.value as string);
      setColumns(ids);
      setTimeout(() => {
        onSelectedColumnsChange(ids);
      }, 0);
    },
    [onSelectedColumnsChange],
  );

  return (
    <PopupSelect
      components={{ Option: CheckboxOption }}
      value={selectedColumns}
      options={columnsOptions}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isMulti
      target={({ isOpen, ...triggerProps }) => (
        <Button {...triggerProps} isSelected={isOpen} iconAfter={<ChevronDownIcon label="" />}>
          Columns
        </Button>
      )}
      onChange={onChange}
    />
  );
}
